// Step 1: Import React
import * as React from 'react'

import { Link } from 'gatsby';
import { Layout, Menu, Breadcrumb, Divider, Typography } from 'antd';

import { site_layout_content, HeaderTitle} from '../components/layout.module.css';
import {MyFace} from '../components/MyFace.js';

import { MyHeader } from "../components/MyHeader.js"
import { MyFooter } from "../components/MyFooter.js"

const { SubMenu } = Menu;
const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography



const App = () => {
  return (
    <main>
      <Layout>
        <MyHeader>
        </MyHeader>

        <Content>
          <div className={site_layout_content}>Content</div>
        </Content>

        <MyFooter>
        </MyFooter>
      </Layout>
    </main>
  )
}

// Step 3: Export your component
export default App